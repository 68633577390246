import BannerImage from "src/images/inline-svg/banner.svg";
import Container from "src/components/ui/Container";
import { PrimaryButton } from "../ui/Buttons";

export default function Banner() {
  return (
    <Container backgroundClassName="bg-white" className="flex bg-white py-16">
      <div className="flex-1 flex flex-col justify-center items-center md:items-start">
        <h1 className="font-bold text-3xl md:text-5xl leading-tight uppercase text-center md:text-left">
          <div>Connecting</div>
          <div>react developers.</div>
        </h1>
        <p className="text-lg md:text-xl mt-4 text-gray-500 text-center md:text-left">
          Community to learn from each other with meetups, live streams and much
          more.
        </p>
        <div className="mt-12">
          <PrimaryButton lg={true} href="https://twitter.com/reactify_in">
            Join us
          </PrimaryButton>
        </div>
      </div>
      <div className="hidden md:block md:flex-1 ml-24">
        <BannerImage />
      </div>
    </Container>
  );
}
