import Head from "next/head";
import Layout from "src/components/ui/Layout";
import Container from "src/components/ui/Container";
import BannerImage from "src/images/inline-svg/banner.svg";
import { getUpcomingEvents } from "src/server/events";
import EventList from "src/components/events/EventList";
import Banner from "src/components/home/Banner";

export default function Home({ events }) {
  return (
    <Layout>
      <Banner />
      <Container>
        {events && events.length > 0 && (
          <div className="py-20 md:py-40">
            <EventList events={events} title="Upcoming events" />
          </div>
        )}
      </Container>
    </Layout>
  );
}

export async function getStaticProps() {
  const events = await getUpcomingEvents();
  return {
    props: {
      events,
    },
    revalidate: 5,
  };
}
