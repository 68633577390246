import classNames from "classnames";

export default function Card({ children, className }) {
  return (
    <div
      className={classNames(
        "bg-white rounded-lg border border-gray-200 px-2 md:px-8 py-2 md:py-4",
        className
      )}
      style={{
        boxShadow: "0 4px 20px rgba(0,0,0,0.12)",
      }}
    >
      {children}
    </div>
  );
}
