import { Heading } from "src/components/ui/Text";
import Card from "src/components/ui/Card";
import Link from "next/link";
import dayjs from "dayjs";
import classNames from "classnames";

function EventItem({ event, className }) {
  return (
    <Card className={className}>
      <Link href={`/events/${event.id}`}>
        <a className="font-semibold text-xl md:text-2xl">{event.title}</a>
      </Link>
      <div className="text-gray-500 mt-2">
        {dayjs(event.startDate).format("dddd, MMMM DD, YYYY")}
        <div className="text-sm mt-1">
          {dayjs(event.startDate).format("h A")} -{" "}
          {dayjs(event.endDate).format("h A")} IST
        </div>
      </div>
    </Card>
  );
}

export default function EventList({
  events,
  title,
  emptyStateTitle = "",
  className,
}) {
  return (
    <div className={classNames("mx-auto max-w-2xl", className)}>
      <Heading className="mb-10 text-center">{title}</Heading>
      {events && events.length > 0 ? (
        events.map((event, index) => (
          <EventItem
            key={event.id}
            event={event}
            className={classNames({ "mb-4": index !== events.length - 1 })}
          />
        ))
      ) : (
        <div className="text-center">{emptyStateTitle}</div>
      )}
    </div>
  );
}
